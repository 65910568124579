.attributesRow {
  /*height: auto;*/
  /*background-color: blue;*/
  margin: 0 !important;
}

.attributesCol {
  min-height: auto !important;
  /*padding: 0 !important;*/
  padding: 0 2rem !important;
}

.flexrowbetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.attrBtn,
.attrBtnPayment {
  width: 100%;
  max-width: 100%;
  min-height: 4.8rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #767e9c3d;
  background-color: transparent;
  margin-bottom: 2rem;
}

.attrBtnActive {
  border: 1px solid #b561ff;
  background-color: #faf4ff;
}

.galleryImg {
  width: 100%;
  min-height: 61.2rem;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.galleryImgOverlay {
  background: rgba(0, 0, 0, 0.5);
  /*z-index: -1;*/
  position: absolute;
  min-height: 100%;
}

.thumbnailGridContainer {
  display: grid;
  grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 11.5rem);
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
  column-gap: 1rem;
}
.discoverGridContainer {
  display: grid;
  grid-template: repeat(auto-fill, 10.4rem) / repeat(3, 10.4rem);
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
  column-gap: 1rem;
}

.galleryImgThumbnail {
  width: 11.5rem;
  height: 16.2rem;
  object-fit: cover;
  border-radius: 8px;
  /*margin-right: 2rem;*/
  /*margin-bottom: 2rem;*/
  cursor: pointer;
}

.galleryImgThumbnailOverlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  /*min-height: 100%;*/
}

.galleryImgThumbnailDesc {
  position: absolute;
  z-index: 1;
  bottom: 2rem;
  left: 0.5rem;
}

@media (max-width: 320px) {
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 10.4rem);
  }
  .galleryImgThumbnail {
    width: 9rem;
  }
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 9rem);
    row-gap: 4rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 10.4rem);
    row-gap: 4rem;
  }
}

@media (min-width: 768px) {
  .galleryImgThumbnail {
    width: 13.4rem;
  }
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(2, 13.4rem);
    row-gap: 2rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(3, 13.4rem);
    row-gap: 2rem;
  }
  .attributesRow {
    /*height: auto;*/
    /*background-color: blue;*/
    margin: 0 12rem !important;
  }
  .attrBtn {
    width: 16.8rem;
  }
  .attrBtnPayment {
    width: 27.6rem;
  }
  .flexrowbetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  .galleryImgThumbnail {
    width: 13.4rem;
  }
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(3, 13.4rem);
    row-gap: 2rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(4, 13.4rem);
    row-gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .galleryImgThumbnail {
    width: 18.4rem;
  }
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(3, 18.4rem);
    row-gap: 2rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(5, 13.4rem);
    row-gap: 2rem;
  }
}
