.auth__heroArea {
  background: url("../../images/auth/hero.png") no-repeat 50%;
  background-size: cover;
  height: 50vh;
}

.auth .auth-popUp {
  height: 100%;
  min-height: 47.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin-top: -4rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 10rem;
}

.auth .content {
  display: grid;
}

.auth .auth-popUp .right {
  background-color: #fafafa;
  padding-bottom: 10rem;
  margin-bottom: -6rem;
}
.auth .auth-popUp .col-md-6 {
  min-height: 47.4rem;
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.dotted-divider {
  border: 1px dashed lightgrey;
  width: 50%;
}

.whisperText {
  -webkit-text-stroke: 0.1rem #b561ff;
  color: transparent;
  font-family: "tt_normsbold_italic" !important;
  font-size: 7rem !important;
}

.social-icons {
  width: 100%;
  height: 5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.auth .or-divider {
  position: absolute;
  top: 42.2%;
  left: 48.5%;
}

.typeOfUser__cards > div {
  min-width: 100%;
  min-height: 23.3rem;
  background-color: #f6f7f7;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 4rem;
  cursor: pointer;
}
.typeOfProvider__cards > div {
  width: 100%;
  min-height: 23.3rem;
  background-color: #f6f7f7;
  border-radius: 10px;
  padding: 1rem 2rem;
  cursor: pointer;
  margin-bottom: 4rem;
  /*margin: 0 2rem 4rem 2rem;*/
}

.typeOfProvider__cards {
  margin: 0 4rem;
}

.typeOfUser__cards .active,
.typeOfProvider__cards .active {
  border: 2px solid var(--primary-light-color);
}

.createUrProfile .uploadBox {
  width: 100%;
  border: 1px dashed grey;
  height: 22rem;
  margin: 2rem 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.uploadPhotos .uploadBox {
  width: 100%;
  border: 1px dashed #b561ff;
  background: #faf4ff;
  height: 25rem;
  margin: 2rem 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.createUrProfile .uploadBox.rounded {
  width: 30rem;
  border: 3px dashed var(--primary-color);
  padding: 0.5rem;
  height: 30rem;
  margin: 2rem 0;
  border-radius: 50% !important;
  cursor: pointer;
  overflow: hidden;
}

.verifyPhone {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .verifyPhone {
    padding-left: 22rem;
    padding-right: 22rem;
  }
  .createUrProfile .uploadBox {
    width: 34.4rem;
    height: 23rem;
    border: 1px dashed grey;
    margin: 0;
  }

  .auth .or-divider {
    position: absolute;
    top: 50%;
    left: 48.5%;
  }
  .auth .auth-popUp .right {
    padding-bottom: 0;
    margin-bottom: -4rem;
  }
  .whisperText {
    font-size: 12rem !important;
  }

  .auth .auth-popUp {
    margin-top: -6rem;
  }

  .social-icons {
    width: auto;
    height: auto;
  }
  .typeOfUser__cards > div {
    min-width: 21.6rem;
  }
  .typeOfProvider__cards > div {
    width: 21.6rem;
    /*margin: 0;*/
  }

  .typeOfProvider__cards {
    margin: 0;
  }

  .uploadPhotos .uploadBox {
    width: 16.8rem;
    height: 16.8rem;
  }
}
