.chat .thumbnailGridContainer {
  display: grid;
  grid-template: repeat(auto-fill, 10.4rem) / repeat(3, 10.4rem);
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
  column-gap: 1rem;
}

.chat .galleryImgThumbnail {
  width: 7rem;
  height: 9.4rem;
  object-fit: cover;
  border-radius: 8px;
  /*margin-right: 2rem;*/
  /*margin-bottom: 2rem;*/
  cursor: pointer;
}

.chat .galleryImgThumbnailOverlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  /*min-height: 100%;*/
}

.chat__list,
.chat__msgs {
  height: 89vh;
  overflow: scroll;
}

.chat__list{
  border-right: 1px dotted grey;
}

.chat__hide__col {
  display: none;
}

.conversation {
  width: fit-content !important;
  border-radius: 8px;
  padding: 2rem;
  display: flex;

}

.conversation__sender {
  background: #f6f6f7;
  color: #596282;
  /*left: 0;*/
}

.conversation__receiver {
  background: #f3e5ff;
  color: #31005c;
  /*right: 0;*/
}

.receiver__container {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

@media (max-width: 320px) {
  .chat .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 10.4rem);
    row-gap: 4rem;
  }
}

@media (min-width: 768px) {
  .chat .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 5rem) / repeat(4, 7rem);
    row-gap: 3rem;
  }
  .chat .galleryImgThumbnail {
    width: 7rem;
  }
  .chat__hide__col {
    display: block;
  }
}
