.badges {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.8rem;
  padding: 0.5rem 1rem;
  border-radius: 14px;
  font-weight: bolder;
  margin-right: 1rem;
  font-family: "tt_normsmedium", sans-serif !important;
  margin-bottom: 1rem;
}

.badges h5 {
  opacity: 0.8;
}

.badges-primary {
  background: #f3e5ff;
}

.badges-secondary {
  background: #ffd9da;
}

.badges-tertiary {
  background: rgba(25, 181, 248, 0.08);
}

.badges-success {
  background: #fef7e0;
}

.actions {
  position: absolute;
  z-index: 2;
  bottom: 3rem;
  width: 100%;
}

.actions .actions__container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.actions img {
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
}

.prevNextImg {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 50%;
}
.arrow {
  width: 4.1rem;
  height: 4.1rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.currentTotalImg {
  width: 4.3rem;
  height: 2.2rem;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.4rem;
  position: absolute;
  color: #fff;
  z-index: 1;
  right: 2rem;
  top: 2rem;
}

@media (min-width: 768px) {
  .provider-home {
    padding: 0 5rem;
  }
  .badges {
    margin-bottom: 0;
  }
  .actions .actions__container {
    padding: 0 3rem;
  }
}
