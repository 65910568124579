.accordion-item {
  border-bottom: 1px solid #d3d3d3;
  margin-left: -2rem !important;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  background: transparent !important;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  padding: 2rem !important;
}

.accordion-wrapper .accordion-item h3.accordion-title button:hover {
  background-color: transparent !important;
  color: #000 !important;
  /*border-bottom: 1px solid grey !important;*/
  border-radius: 0 !important;
}

.accordion-wrapper .accordion-item h3.accordion-title button.active {
  background-color: transparent !important;
  color: #000 !important;
  border: 0 !important;
  /*border-bottom: 1px solid grey !important;*/
  border-radius: 0 !important;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  background: none !important;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity: 1;
  width: 100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
  /*border-bottom: 1px solid grey !important;*/
  border-radius: 0 !important;
  margin-top: -2rem !important;
  padding-left: 2rem !important;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 1rem;
  border: none;
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
  color: #000;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 1rem;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open:hover {
  overflow: hidden;
  background: none !important;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity: 1;
  width: 100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
  /*border-bottom: 1px solid grey !important;*/
  border-radius: 0 !important;
  margin-top: -2rem !important;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.icon-wrapper
  span.minus {
  content: url("../images/SVG/plus.svg") !important;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.icon-wrapper
  span.plus {
  content: url("../images/SVG/minus.svg") !important;
  transform: none !important;
  width: 2rem;
  height: 2rem;
}

.accordion-wrapper
  .accordion-item
  h3.accordion-title
  button
  span.title-wrapper {
  color: #000;
  font-weight: bolder;
  font-size: 1.5rem;
}
