@font-face {
  font-family: "tt_normsitalic";
  src: url("./fonts/ttnorms-italic-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normslight";
  src: url("./fonts/ttnorms-light-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normsmedium";
  src: url("./fonts/ttnorms-medium-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normsregular";
  src: url("./fonts/ttnorms-regular-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normsthin";
  src: url("./fonts/ttnorms-thin-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normsbold";
  src: url("./fonts/ttnorms-bold-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_normsbold_italic";
  src: url("./fonts/ttnorms-bolditalic-webfont.woff2") format("woff2"),
    url("./fonts/ttnorms-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #31005c;
  --secondary-color: #f2a14b;
  --primary-light-color: #b561ff;
  --tertiary-color: #e8467e;
  --tertiary-light-color: #f9e5f4;
  --white: #fafafa;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  /*equivalent to 10px*/
  font-size: 62.5% !important;
}

body {
  font-family: "tt_normslight", sans-serif !important;
  background-color: #fff !important;
  color: #23143f !important;
  /*letter-spacing: 0 !important;*/
  font-size: 1.6rem !important;
  opacity: 1 !important;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/*Typography*/
h1,
h2,
h3,
h4,
h5,
h6 {
  /*ds place 1rem space btw our heading and their text so it can look closer to what we have on the mockup*/
  line-height: 1.1 !important;
  margin-bottom: 1rem !important;
  font-weight: bold !important;
  font-family: "tt_normsbold", sans-serif !important;
}

h1 {
  /*with html font-size set to 62.5% this is 70px*/
  font-size: 7rem !important;
}

h2 {
  font-size: 4rem !important;
}

h3 {
  font-size: 3rem !important;
}

p {
  /*in other to avoid margin collapsing bcos paragraph element comes with their own margins so we set this to 0 so only the margin bottom of our headings will take prominent*/
  margin-top: 0 !important;
  font-size: 1.6rem;
}

/*Desktop*/
@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem !important;
  }

  p {
    font-size: 1.8rem !important;
  }

  h1 {
    font-size: 6rem !important;
  }

  h2 {
    font-size: 4rem !important;
  }

  h3 {
    font-size: 2.8rem !important;
  }
  h4 {
    font-size: 1.999rem !important;
  }
  h5 {
    font-size: 1.414rem !important;
  }
  h6 {
    font-size: 1rem !important;
  }
}

.text-light {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-tertiary {
  background-color: var(--tertiary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-primary-light {
  background-color: var(--primary-light-color) !important;
}

.bg-tertiary-light {
  background-color: var(--tertiary-light-color) !important;
}

/*BUTTONS*/
.btn {
  padding: 1.5rem 3rem !important;
  border-radius: 8px !important;
  font-size: 1.4rem !important;
  font-weight: 600;
  font-family: "tt_normsregular", sans-serif;
}
.btn-primary {
  background: var(--primary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 8px !important;
}

.btn-primary:hover {
  background: #190a4bff !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid #190a4bff !important;
  border-radius: 8px !important;
}

.btn-outline-primary-light {
  background: transparent !important;
  color: var(--primary-light-color) !important;
  border: 1px solid var(--primary-light-color) !important;
  border-radius: 21.5px !important;
  font-size: 1rem !important;
}

.btn-outline-primary-light:hover {
  background: var(--primary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-primary-light:disabled {
  background: transparent !important;
  color: grey !important;
  border: 1px solid grey !important;
}

.btn-light {
  background: var(--white) !important;
  color: var(--primary-color) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--white) !important;
  border-radius: 8px !important;
}

.btn-light:hover {
  background: var(--primary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-primary {
  background: var(--white) !important;
  color: var(--primary-color) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-primary:hover {
  background: #190a4bff !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid #190a4bff !important;
  border-radius: 8px !important;
}

.btn-secondary {
  background: var(--secondary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 8px !important;
}

.btn-secondary:hover {
  background: #945715ff !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid #945715ff !important;
  border-radius: 8px !important;
}

.btn-outline-secondary {
  background: var(--white) !important;
  color: var(--secondary-color) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-secondary:hover {
  background: var(--secondary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-grey {
  background: transparent !important;
  color: #979797 !important;
  font-size: 1.6rem !important;
  border: 1px solid #979797 !important;
  border-radius: 8px !important;
}

.btn-grey {
  background: #979797 !important;
  color: #fff !important;
  font-size: 1.6rem !important;
  border: 1px solid #979797 !important;
  border-radius: 8px !important;
}

.btn-tertiary {
  background: var(--tertiary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--tertiary-color) !important;
  border-radius: 8px !important;
}

.btn-tertiary:hover {
  background: #7a082f !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid #7a082f !important;
  border-radius: 8px !important;
}

.btn-outline-tertiary {
  background: var(--white) !important;
  color: var(--tertiary-color) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--tertiary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-tertiary:hover {
  background: var(--tertiary-color) !important;
  color: var(--white) !important;
  font-size: 1.6rem !important;
  border: 1px solid var(--tertiary-color) !important;
  border-radius: 8px !important;
}

.btn-outline-tertiary.transparent {
  background-color: transparent !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.big-round-btn,
.big-round-btn:hover {
  min-width: 16.1rem !important;
  min-height: 4.8rem !important;
  border-radius: 2.4rem !important;
}

.btn-md-auto {
  width: auto !important;
}

.btn-sm-block {
  width: 100% !important;
}

.primary-text {
  color: var(--primary-color) !important;
}

.primary-light-text {
  color: var(--primary-light-color) !important;
}
.secondary-text {
  color: var(--secondary-color) !important;
}
.tertiary-text {
  color: var(--tertiary-color) !important;
}

.ant-tabs-ink-bar {
  background: var(--secondary-color) !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-color) !important;
}

.cursor {
  cursor: pointer !important;
}

small {
  font-size: 70% !important;
}

.ant-layout-sider {
  /*display: none;*/
  /*flex: 0 0 10vw !important;*/
  /*width: 30vw !important;*/
  /*max-width: 50vw !important;*/
  /*position: relative !important;*/
  background: transparent !important;
  border-right: 1px solid grey !important;
  margin-right: 4rem !important;
  /*transition: all 0.2s !important;*/
}

.ant-table {
  position: relative !important;
  font-size: 1.4rem !important;
  background: transparent !important;
  border-radius: 2px !important;
}
.padding-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tableRowCursor {
  cursor: pointer !important;
}

/*ICONS*/

.tertiary-rounded-icon {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 2.4rem;
  background-color: var(--tertiary-light-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-img {
  width: 1.9rem;
  height: 2.3rem;
  object-fit: contain;
}

/*LAYOUT*/
.flexrow {
  display: block;
  margin-bottom: 2rem;
}
.flexrowbetween {
  display: block;
  margin-bottom: 2rem;
}
.flexrowaround {
  display: block;
  margin-bottom: 2rem;
}
.flexrowevenly {
  display: block;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .btn-outline-primary-light {
    font-size: 1.6rem !important;
  }
  .flexrow {
    display: flex;
    margin-bottom: 2rem;
  }
  .flexrowbetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .flexrowbetweencenter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
  .flexrowaround {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }
  .flexrowaroundcenter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0;
  }
  .flexrowevenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0;
  }
  .flexrowevenlycenter {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 0;
  }
}

.no-bullet-list {
  list-style: none !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.no-bullet-list li {
  padding: 1rem 0 !important;
  cursor: pointer;
}

.ant-form-item-control,
.ant-select-selector {
  border-radius: 10px !important;
  /*overflow: hidden !important;*/
}

.ant-input,
.ant-select,
.ant-picker,
.ant-select-selector,
.react-tel-input .form-control,
.ant-input-affix-wrapper {
  border-radius: 10px !important;
  margin-bottom: 0.5rem !important;
  overflow: hidden !important;
  height: 4.8rem !important;
  background-color: transparent !important;
}

.input-white {
  background-color: white !important;
}

/*.ant-input-affix-wrapper {
  align-items: center !important;
}*/

.ant-input .prepend {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.appendInput {
  background: white;
  border: 1px solid lightgray;
  padding: 1.58rem 1rem;
  z-index: 9;
  margin-left: -1rem;
  border-radius: 0 8px 8px 0;
  margin-bottom: 0.5rem !important;
}

.prependInput {
  width: 9.6rem;
  height: 4.1rem;
  background: #faf4ff;
  border: 1px solid #b561ff;
  padding: 0 1rem;
  z-index: 9;
  /*margin-left: -1rem;*/
  margin-right: -9rem;
  border-radius: 8px;
  margin-bottom: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag {
  border-radius: 10px 0 0 10px !important;
}

.ant-picker {
  width: 100% !important;
}

textarea.ant-input {
  height: auto !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}

/*.react-tel-input .country-list {
  outline: none;
  z-index: 999999999 !important;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: black !important;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}*/

.btn-block {
  width: 100% !important;
}

.inline-form {
  width: 100% !important;
}

.cardz {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.form-input2 {
  border: 2px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 8px 8px 0px !important;
  height: 40px !important;
}

.ant-upload {
  width: 100% !important;
}

.ant-slider {
  margin: 0 !important;
}

@media only screen and (max-width: 500px) {
  .col {
    margin-bottom: 4rem;
  }
}

@media only screen and (max-width: 500px) {
  .col-md-4 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .inline-form {
    width: 21.9rem !important;
  }
  small {
    font-size: 80% !important;
  }

  .prependInput {
    width: 9.6rem;
    height: 4.1rem;
    background: #faf4ff;
    border: 1px solid #b561ff;
    padding: 0 1rem;
    z-index: 9;
    /*margin-left: -1rem;*/
    margin-right: -9.7rem;
    border-radius: 8px;
    margin-bottom: 0.5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*  .ant-layout-sider {
    flex: 0 0 30vw !important;
    display: block;
  }*/
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 1px solid grey !important;
}
