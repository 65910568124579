.filterModal__gender {
  border-radius: 10px !important;
  margin-bottom: 0.5rem !important;
  overflow: hidden !important;
  min-height: 4.8rem !important;
  background-color: transparent !important;
  border: 1px solid rgba(118, 126, 156, 0.24);
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.filterModal__gender__item {
  color: #767e9c !important;
  background: transparent;
  min-width: 11rem;
  height: 3.2rem;
  border-radius: 4px;
  border: 1px solid rgba(118, 126, 156, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bolder;
  margin-bottom: 0.5rem;
}

.filterModal__gender__item.active {
  color: var(--primary-color) !important;
  background: #faf4ff;
  border: 1px solid #daafff;
}
