.leftColumn {
  min-height: 80vh;
  max-height: 100%;
  margin-top: 2rem;
}

.profileDetailsContainer {
  min-height: 61.2rem;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  overflow: hidden;
}
.galleryImg {
  width: 100%;
  min-height: 61.2rem;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.galleryImgOverlay {
  background: rgba(0, 0, 0, 0.5);
  /*z-index: -1;*/
  position: absolute;
  min-height: 100%;
}

.thumbnailGridContainer {
  display: grid;
  grid-template: repeat(auto-fill, 10.4rem) / repeat(3, 10.4rem);
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
  column-gap: 1rem;
}
.discoverGridContainer {
  display: grid;
  grid-template: repeat(auto-fill, 10.4rem) / repeat(3, 10.4rem);
  justify-items: center;
  align-items: center;
  row-gap: 4rem;
  column-gap: 1rem;
}

.galleryImgThumbnail {
  width: 10.4rem;
  height: 16.2rem;
  object-fit: cover;
  border-radius: 8px;
  /*margin-right: 2rem;*/
  /*margin-bottom: 2rem;*/
  cursor: pointer;
}

.galleryImgThumbnailOverlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  /*min-height: 100%;*/
}

.galleryImgThumbnailDesc {
  position: absolute;
  z-index: 1;
  bottom: 2rem;
  left: 0.5rem;
}

.profileContainerRightCol {
  padding: 2rem;
}

@media (max-width: 320px) {
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 10.4rem);
    row-gap: 4rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 10.4rem) / repeat(2, 10.4rem);
    row-gap: 4rem;
  }
}

@media (min-width: 768px) {
  .thumbnailGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(2, 13.4rem);
    row-gap: 2rem;
  }
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(3, 13.4rem);
    row-gap: 2rem;
  }
  .leftColumn {
    border-left: 1px solid #f6f7f7;
    margin: 0;
    padding-left: 5rem;
  }

  .profileDetailsContainer {
    width: 95%;
  }
  .galleryImgThumbnail {
    width: 13.4rem;
  }
}

@media (min-width: 1024px) {
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(4, 13.4rem);
    row-gap: 2rem;
  }
}

@media (min-width: 1440px) {
  .discoverGridContainer {
    grid-template: repeat(auto-fill, 13.4rem) / repeat(5, 13.4rem);
    row-gap: 2rem;
  }
}
