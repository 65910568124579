.landing {
  background-color: #e5e5e5;
}

.landing .hero-area {
  height: 100vh;
}

.hero-area .hero-area__bg {
  background: url("../../assets/images/home/hero-area.jpg") no-repeat 50%;
  background-size: cover;
  height: 100%;
  overflow: scroll;
}

.landing .btn {
  width: 100%;
}

.hero-area .logo {
  width: 13.1rem;
  object-fit: contain;
}

.landing .whisperText {
  margin-top: 7.4rem;
}

.landing .straightline {
  width: 16.9rem;
  height: 0.1rem;
  background-color: #d5a5ff;
}

.straightline-white {
  width: 100%;
  height: 0.1rem;
  background-color: #fff;
}

.landing .heading {
  margin-top: -3.8rem;
  font-size: 3rem !important;
}

.landing .storeapps {
  width: 12rem;
  cursor: pointer;
}
.landing .storeapps:last-child {
  margin: 1rem 0;
}

.landing .create-account {
  margin-top: 2rem;
}

.landing .mini-sections {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
}

.landing .beautiful {
  max-width: 100%;
  /*height: 80rem;*/
  object-fit: contain;
}

/*.landing .hero-area-two {
  background: url("../../assets/images/home/hero-area-two.jpg") no-repeat 50%;
  background-size: cover;
  width: 100%;
  !*height: 50%;*!
}*/

.landing .hero-area-two {
  height: 63.4rem;
  width: 100vw;
  background-size: cover;
  background: url("../../assets/images/home/hero-area-two.jpg") no-repeat 50%
    50%;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  min-height: 32.6rem;
  width: 100vw;
  background-color: #000;
  display: block;
  color: #fff;
}

.footer-item {
  margin-left: 2rem;
}

.landing .straightline-white {
  margin-left: 0;
}

.landing .logoListitem {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .landing .straightline-white {
    margin-left: 4rem;
  }
  .footer {
    min-height: 32.6rem;
    width: 100vw;
    background-color: #000;
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: space-around;
    color: #fff;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .landing .btn {
    width: auto;
  }

  .landing .heading {
    margin-top: -6rem;
    font-size: 6rem !important;
  }

  .landing .storeapps {
    display: inline;
    margin: auto;
    width: 21rem;
  }
  .landing .storeapps:first-child {
    margin-right: 2rem;
  }
  .landing .create-account {
    margin-top: 5rem;
  }
}
