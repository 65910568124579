.paymentModal__cards {
  height: 7.7rem;
  width: 100%;
  background: #faf4ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.paymentModal__cards img {
  width: 3.8rem;
  height: 3.8rem;
  margin-right: 1rem;
}

.paymentModal__cards.active {
  border: 1px solid #b561ff;
}

.paymentModal .wallet-container {
  width: 100%;
  min-height: 17.8rem;
  border-radius: 10px;
  background: var(--primary-color)
    url("../images/homeInApp/patternBackground.svg");
  margin-bottom: 2rem;
  padding: 1rem;
}

.paymentModal .wallet-container button {
  width: 100%;
}

@media (min-width: 768px) {
  .paymentModal__cards {
    height: 7.7rem;
  }
  .paymentModal .wallet-container {
    width: 100%;
  }
  .paymentModal .wallet-container button {
    width: auto;
  }
}
@media (min-width: 1440px) {
  .paymentModal .wallet-container {
    height: 17.8rem;
  }
  .paymentModal__cards {
    height: 7.7rem;
    width: 28.8rem;
  }
  .paymentModal .wallet-container {
    width: 36rem;
  }
}
